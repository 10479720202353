// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-detail-js": () => import("./../src/templates/blogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-showreel-js": () => import("./../src/templates/showreel.js" /* webpackChunkName: "component---src-templates-showreel-js" */),
  "component---src-templates-thank-you-js": () => import("./../src/templates/thankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-work-detail-js": () => import("./../src/templates/workDetail.js" /* webpackChunkName: "component---src-templates-work-detail-js" */),
  "component---src-templates-work-js": () => import("./../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

